.App {
  text-align: center;
  margin: 0;
  background: transparent;
  > div,
  input,
  textarea {
    color: white !important;
    margin: 0;
    background: transparent;
    box-shadow: none;
    label {
      color: white !important;
    }
    > div {
      max-width: 100%;
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.comment {
  background-color: #1f2a37;
  border: 1px solid #4b5663 !important;
  border-radius: 5px;
  p {
    text-align: left;
  }
  button {
    float: left;
  }
}

.MuiFormControl-root {
  margin: 0 !important;
}

.comment-form {
  align-items: center;
  display: flex;
  gap: 20px;
  flex-direction: column;
  margin-bottom: 20px;
}

.comment-button {
  font-size: 0.875rem !important;
  width: 180px !important;
  height: 50px !important;
}

.general-button {
  cursor: pointer;
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.15),
    hsla(0, 0%, 100%, 0)
  );
  background-color: #2ea44f !important;
  box-shadow: 0 1px 1px rgba(18, 21, 26, 0.075);
  color: white !important;
  font-weight: bold !important;
}

.chat-icon {
  width: 20px;
  height: 20px;
}

.comment-input {
  margin-bottom: 2;
  background-color: #1f2a37;
  border: 1px solid #4b5663 !important;
  border-radius: 5px;
  position: relative;
  transition: all 0.3s ease;

  .MuiInputBase-root {
    padding: 0px !important;
  }

  textarea {
    padding: 13px;
  }

  .Mui-focused {
    input,
    textarea {
      background-color: #374251;
    }
    fieldset {
      border-color: #2ea44f !important;
    }
  }

  :hover {
    fieldset {
      border-color: #2ea44f !important;
    }
  }

  /* Unified drag overlay for main comments and replies */
  .drag-overlay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(46, 164, 79, 0.2);
    border: 2px dashed #2ea44f;
    align-items: center;
    justify-content: center;
    z-index: 10;
    pointer-events: none;
    border-radius: 4px;
    transition: all 0.3s ease;
  }

  .drag-overlay-text {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 1.1rem;
  }

  &.drag-over {
    border-color: #2ea44f !important;

    .drag-overlay {
      display: flex;
    }
  }
}

.reply-comment-input {
  input {
    padding-right: 80px;
  }
}

.comment-content,
.reply-comment-content {
  hyphens: auto;
}

/* File upload styles */
.drop-area {
  transition: all 0.3s ease;

  &.drag-over {
    border-color: #2196f3 !important;
    background-color: rgba(33, 150, 243, 0.1) !important;
  }
}

/* Attachment preview thumbnails */
.attachment-preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.attachment-preview-item {
  width: 50px;
  height: 50px;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #e0e0e0;
  background-color: #f5f5f5;
  transition: transform 0.2s ease;
}

.attachment-preview-item:hover {
  transform: scale(1.05);
}

.attachment-preview-item .delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.attachment-preview-item:hover .delete-icon {
  display: flex;
}

/* Attachment display in comments */
.comment-attachments {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.comment-attachment {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
}

.comment-attachment img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
